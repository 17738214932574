<template>
  <div>
  </div>
</template>

<script>
import firebase from 'firebase';
firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENTID
});

export default {
  name: "Top",
  mounted() {
    firebase
        .auth()
        .getRedirectResult()
        .then(function(result) {
          if (result.credential) {
            // You can get the Apple OAuth Access and ID Tokens.
            var accessToken = result.credential.accessToken;
            var idToken = result.credential.idToken;

            var json = {
              accessToken: accessToken,
              idToken: idToken
            };
            document.body.innerHTML = JSON.stringify(json);
            location.href = location.origin+'/?success';
          } else {
            if (!location.href.includes('?success')) {
              var provider = new firebase.auth.OAuthProvider('apple.com');
              provider.addScope('email');
              provider.addScope('name');
              provider.setCustomParameters({
                // Localize the Apple authentication screen in French.
                locale: 'ja_JP'
              });
              firebase.auth().signInWithRedirect(provider);
            }
          }
        })
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          // [START_EXCLUDE]
          if (errorCode === 'auth/account-exists-with-different-credential') {
            alert(
                'You have already signed up with a different auth provider for that email.'
            );
            // If you are using multiple auth providers on your app you should handle linking
            // the user's accounts here.
          } else {
            console.error(error);
          }
          // [END_EXCLUDE]
        });
  }
}
</script>

<style scoped>

</style>
